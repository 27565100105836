import axios from "axios";
import { Toast } from "vant";
// import qs from "qs";
const service = axios.create({
  baseURL: "http://mpc.irealcare.com/",
  timeout: 5000, // 请求超时时间
});

//请求
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

//响应
service.interceptors.response.use(
  (response) => {
    const status = response.status;
    if (status === 200) {
      let result = response.data;
      if (response.request.responseType === "blob" && result) {
        return Promise.resolve(response);
      } else if (result.ref == 200) {
        return Promise.resolve(response);
      } else {
        // 错误统一提示
        // let msg = [...result.result, result.result].join('，');
        // message.error(msg || '');
        return Promise.reject(response);
      }
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    let { response } = error;
    var errorMsg = "";
    if (response) {
      Toast(errorMsg || "");
      return false;
    } else {
      //服务器连结果都没有返回  有可能是断网或者服务器奔溃
      if (!window.navigator.online) {
        //断网处理
        Toast("网络中断");
        return;
      } else {
        //服务器奔了
        Toast("服务器崩了");
        return Promise.reject(error);
      }
    }
  }
);

let http = {};

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

http.get = function (url, params = null) {
  return new Promise((resolve, reject) => {
    service
      .get(url, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * getBlob方法，对应get请求, 用于请求blob数据
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

http.getBlob = function (url, params = null) {
  return new Promise((resolve, reject) => {
    service({
      url,
      params,
      method: "get",
      responseType: "blob",
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

http.post = function (url, params) {
  return new Promise((resolve, reject) => {
    service
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export default http;
